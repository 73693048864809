import React from 'react'
import {useState, useRef} from 'react'
import {KTIcon, QUERIES, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {importBusinesses} from '../../core/_requests'
import useBusinessPageInfo from '../../../../../../utils/useBusinessPageInfo'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useQueryClient, useMutation} from 'react-query'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useListFailedView} from '../../core/ListFailedProvider'

export const ExcelToJsonConverter = () => {
  const queryClient = useQueryClient()
  const {setFailedList} = useListFailedView()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const {page, businessId} = useBusinessPageInfo()
  const [errorMessage, SetErrMessage] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const uploadFile = useMutation((formData: FormData) => importBusinesses(formData), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(`business_imported_list`)
      setSelectedFile(null)
    },
  })
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    setSelectedFile(file || null)
    if (file) {
      const isExcelFile = /\.(xls|xlsx)$/i.test(file.name)
      if (isExcelFile) {
        const formData = new FormData()
        formData.append('excelFile', file)
        formData.append('category', businessId)
        uploadFile.mutate(formData)
      } else {
        SetErrMessage('File Format must be excel (.xls, .xlsx)')
      }
    }
    setSelectedFile(null)
  }

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
      if (fileInputRef.current.value) {
        fileInputRef.current.value = ''
      }
    }
  }
  if (uploadFile.isSuccess) {
    setFailedList(uploadFile.data)
  }
  return (
    <div>
      <input
        type='file'
        accept='.xlsx, .xls'
        onChange={handleFileChange}
        style={{display: 'none'}}
        ref={fileInputRef}
      />
      <button
        type='button'
        id='kt_sign_in_submit'
        onClick={handleUploadButtonClick}
        className='btn btn-light-primary flex align-item-center me-3'
      >
        {!uploadFile.isLoading ? (
          <>
            <KTIcon iconName='exit-up' className='fs-2' />
            <span className='indicator-label'>Upload File</span>
          </>
        ) : null}
        {uploadFile.isLoading && (
          <span className='indicator-progress' style={{display: 'flex'}}>
            Uploading...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>

      {errorMessage ? (
        <div className='cerr_message_wrap'>
          <div className='fv-plugins-message-container  d-flex justify-content-center pt-4'>
            <div className='fv-help-block'>
              <span role='alert'>{errorMessage}</span>
            </div>
          </div>
        </div>
      ) : null}
      {uploadFile.isSuccess ? (
        <div className='cerr_message_wrap'>
          <div className='text_success pt-4'>
            <span role='alert' className='text-center'>
              File Uploaded Successfully and records are updated very soon !
            </span>
          </div>
        </div>
      ) : null}
      {uploadFile.isError ? (
        <div className='cerr_message_wrap'>
          <div className='fv-plugins-message-container  d-flex justify-content-center pt-4'>
            <div className='fv-help-block'>
              <span role='alert' className='text-center'>
                Opps ! Unable to upload file
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
