/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {joinWithComma} from '../../../../../../utils/helperFn'

type Props = {
  address?: string
  city?: string
  state?: string
  zipCode?: string
}

const Location: FC<Props> = ({address, city, state, zipCode}) => (
  <div className='d-flex flex-column'>
    <p className='mb-1 text-sm'>{joinWithComma(address, city)}</p>
    <p className='text-sm'>{joinWithComma(state, zipCode)}</p>
  </div>
)

export {Location}
