import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  isPromoted?: boolean
}

const PromotedStatusCell: FC<Props> = ({isPromoted}) => (
  <>
    <div>
      {isPromoted ? (
        <span className='badge-light-info '>
          <i>Promoted</i>
        </span>
      ) : (
        <span></span>
      )}
    </div>
  </>
)
export {PromotedStatusCell}
