import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const UsersListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [approvalStatus, setApproval] = useState<string | undefined>()
  const [trashedStatus, setTrashedStatus] = useState<string>('')
  const [eCommerceMode, setECommerceMode] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setApproval('')
    setTrashedStatus('without_trash')
    setECommerceMode('')
  }
  // eCommerceMode: eCommerceMode
  const filterData = () => {
    updateState({
      filter: {
        approvalStatus: approvalStatus,
        trashedStatus: trashedStatus !== 'promoted' ? trashedStatus : '',
        promoted: trashedStatus === 'promoted' ? true : false,
        eCommerceMode: eCommerceMode,
      },
      ...initialQueryState,
    })
  }
  const filterTrashedHandler = (value) => {
    setTrashedStatus(value)
  }
  const filterHandler = (value) => {
    if (value === 'Yes') {
      setECommerceMode('Yes')
    } else {
      setECommerceMode('')
    }
    setApproval(value)
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Filter:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              placeholder='Select Filter'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='approvalStatus'
              data-hide-search='true'
              onChange={(e) => filterHandler(e.target.value)}
              value={approvalStatus}
            >
              <option value='' disabled>
                Select Filter
              </option>
              <option value='Pending'>Pending</option>
              <option value='Verified'>Verified</option>
              <option value='Rejected'>Rejected</option>
              <option value='Yes'>E-Commerce Enabled</option>
            </select>
          </div>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'> Select Trashed Records:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              placeholder='Select Filter'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='trashedStatus'
              data-hide-search='true'
              onChange={(e) => filterTrashedHandler(e.target.value)}
              value={trashedStatus}
            >
              <option value='without_trash'>With out Trashed</option>
              <option value='with_trashed'>With Trashed</option>
              <option value='trashed'>Only Trashed</option>
              <option value='promoted'>Only Promoted</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
