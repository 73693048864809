import { ID, Response } from '../../../../../_metronic/helpers'




export type Business = {
  id?: ID
  name?: string
  avatar?: string
  email?: string
  status?: string
  approvalStatus?: string
  totalReviews?: number
  averageRating?: number
  description?: string
  phone?: string
  createdAt?: string
  website?: string
  category?: ID
  owner?: string
  delegateUserEmail?: string
  eCommerceEnabled?: any
  businessMode?: string
  reviews?: string
  rating?: string
  thumbnail?: string
  amenities?: string[]
  isPromoted?: boolean
  timetable?: string[]
  youtube?: string
  instagram?: string
  facebook?: string
  images?: string[]
  videos?: string[]
  address?: string
  city?: string
  products?: []
  state?: string
  zipCode?: string
}

export type BusinessQueryResponse = Response<Array<Business>>

export const initialBusiness: Business = {
  avatar: '',
  status: '',
  name: '',
  email: '',
  state: 'OH',
  phone: '',
  address: '',
  amenities: [] as string[],
  city: 'Mason',
  eCommerceEnabled: 'No',
  businessMode: 'Offline',
}
