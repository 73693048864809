import React from 'react'
const thumbStyle: React.CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

export const Previews = ({files, removeFile}) => {
  if (Array.isArray(files) && !files.length) return null

  return (
    <>
      {Array.isArray(files) &&
        files?.map((file, index) => (
          <div className='files_wrap files_k_wrap' style={thumbStyle} key={index}>
            <div className='bin_file custom_bin '>
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove Thumbnail'
                onClick={() => removeFile(index, file)}
              >
                <i className='bi bi-x fs-2'></i>
              </span>
            </div>
            <div style={thumbInner}>
              <img
                src={file.preview ? file.preview : file}
                style={img}
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(file.preview)
                }}
              />
            </div>
          </div>
        ))}
    </>
  )
}
