/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { InformationCategory } from '../../core/_models'
import { ID, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { useInformationCategory } from '../../../../../_metronic/layout/components/sidebar/sidebar-menu/menuData'
import _ from 'lodash'
type Props = {
  information: InformationCategory
  id: ID
  // avatar:string
}

const InfoCell: FC<Props> = ({ id, information }) => {
  const { data, error, isLoading } = useInformationCategory()
  const navigate = useNavigate()
  // transform businesses
  const infoCategoryList = data?.data?.map((obj) => {
    const { id, name, ...rest } = obj
    return {
      id: id,
      name: _.replace(name, /%20/g, ' '),
      url: name.trim()
        .replace(/[^a-zA-Z0-9]+/g, "-")
        .toLowerCase(),
    }
  })

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const viewAllBusinessHandler = () => {
    const infoCategory = infoCategoryList.find((item) => item.id === id)
    navigate(`/information/${infoCategory.id}/${infoCategory.url}`)
  }
  return (
    <div onClick={viewAllBusinessHandler} className='d-flex align-items-center pointer'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a>
          <div className='symbol-label'>
            <img
              src={information.avatar ? information.avatar : blankImg}
              alt={information?.name}
              className='w-100'
            />
          </div>
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{information?.name}</a>
      </div>
    </div>
  )
}

export { InfoCell }
