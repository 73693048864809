/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Business} from '../../core/_models'
import {useLocation, useNavigate} from 'react-router-dom'

type Props = {
  business: Business
}

const UserInfoCell: FC<Props> = ({business}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const openViewPage = () => {
    // setItemIdForUpdate(id)
    navigate({
      pathname: `${location.pathname}/detail`,
      search: `?Id=${business.id}`,
    })
  }
  return (
    <div onClick={openViewPage} className='d-flex align-items-center pointer'>
      <div className='d-flex flex-column'>
        <a className='text-gray-800 text-hover-primary mb-1'>{business.name}</a>
        <span>{business.email}</span>
      </div>
    </div>
  )
}

export {UserInfoCell}
