// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Business} from '../../core/_models'
import {format} from 'date-fns'
import {ReviewRating} from './ReviewRating'
import {Location} from './Location'
import {StatusCell} from '../../../../offers/table/columns/StatusCell'
import {ApprovalStatusCell} from '../../../../offers/table/columns/ApprovalStatusCell'
import {PromotedStatusCell} from '../../../../offers/table/columns/PromotedStatusCell'
const showCategoryColumn = window?.location?.pathname === '/businesses/list'
const usersColumns: ReadonlyArray<Column<Business>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell business={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='' className='min-w-125px' />,
    id: 'category',
    Cell: ({...props}) => props.data[props.row.index].category?.name,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Approval' className='min-w-125px' />
    ),
    id: 'approvalStatus',
    Cell: ({...props}) => (
      <ApprovalStatusCell approvalStatus={props.data[props.row.index].approvalStatus} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Join Date' className='min-w-125px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => {
      return format(new Date(props.data[props.row.index].createdAt), 'MMM dd, yyyy')
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Promoted' className='min-w-125px' />
    ),
    id: 'isPromoted',
    Cell: ({...props}) => (
      <PromotedStatusCell isPromoted={props.data[props.row.index].isPromoted} />
      // <h1>{props.data[props.row.index].isPromoted}</h1>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Location' className='min-w-125px' />
    ),
    // accessor: 'address',
    id: 'location',
    Cell: ({...props}) => (
      <Location
        address={props.data[props.row.index].address}
        city={props.data[props.row.index].city}
        zipCode={props.data[props.row.index].zipCode}
        state={props.data[props.row.index].state}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        id={props.data[props.row.index].id}
        isDeleted={!!props.data[props.row.index]?.deletedAt}
      />
    ),
  },
]

export {usersColumns}
