import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {OfferTypesWrapper} from '../pages/offers/OfferTypesWrapper'
import {BusinessCategoryPage} from '../pages/business-category/BusiessCategoryPage'
import {InformationCategoryPage} from '../pages/information-category/InformationCategoryPage'
import {MainCategoryPage} from '../pages/main-category/MainCategoryPage'
import {InformationDepartmentDetail} from '../modules/information-department/components/Info-department-add-edit/InformationDepartmentDetail'
import {BusinessDetail} from '../modules/apps/business-management/components/business-add-edit/BusinessDetail'
import {OfferDetailPage} from '../modules/offers/offer-edit/OfferDetailPage'
import PlanPage from '../modules/Plans/PlanPage'
import ProfilePage from '../modules/profile/ProfilePage'
import {InquiryPage} from '../pages/user-inquiry/InquiryPage'
import {EnquiryDetailPage} from '../modules/user-enquiry/table/EnquiryDetail'
import {BlogCategoryWrapper} from '../pages/blog-pages/BlogCategoryWrapper'
import {NewsCategoryWrapper} from '../pages/news-stories/NewsCategoryWrapper'

import {FaqQnaWrapper} from '../pages/faq-pages/FaqQnaWrapper'
import FaqQnaDetails from '../modules/faq-cotegory/components/FaqQnaDetails'
import {UpdatePassword} from '../modules/profile/UpdatePassword'
import PaymentPage from '../modules/payments/PaymentPage'
import {NewsLetterPage} from '../pages/news-letter-subscriber/NewsLetterPage'

import ServicePage from '../modules/professional-services/ServicePage'

import ManagementHeader from '../modules/management/ManagementHeader'
import {ServiceDetail} from '../modules/professional-services/service-add-edit/ServiceDetail'
import {PostCategoryWrapper} from '../pages/post-category/PostCategoryWrapper'
import BusinessListPage from '../modules/apps/business-management/businesses/BusinessListPage'

const PrivateRoutes = () => {
  const BusinessPage = lazy(
    () => import('../modules/apps/business-management/businesses/BusinessPage')
  )
  const BlogPage = lazy(() => import('../modules/blogs/BlogPage'))
  const NewsPage = lazy(() => import('../modules/news/NewsPage'))
  const PostPage = lazy(() => import('../modules/communityPost/PostPage'))

  const OfferPage = lazy(() => import('../modules/offers/OfferPage'))
  const RealEstatePage = lazy(() => import('../modules/real-estate/RealEstate'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const JobManagementPage = lazy(() => import('../modules/jobs/JobPage'))
  const InfoDepartmentPage = lazy(
    () => import('../modules/information-department/departments/InfoDepartmentPage')
  )
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/updatePassword/*'
          element={
            <SuspensedView>
              <UpdatePassword />
            </SuspensedView>
          }
        />
        <Route
          path='business-categories/*'
          element={
            <SuspensedView>
              <BusinessCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='information-categories/*'
          element={
            <SuspensedView>
              <InformationCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='professional-services/'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />

        <Route
          path='/user-enquiries'
          element={
            <SuspensedView>
              <InquiryPage />
            </SuspensedView>
          }
        />
        <Route
          path='/news-Letter'
          element={
            <SuspensedView>
              <NewsLetterPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-enquiries/eq-detail'
          element={
            <SuspensedView>
              <EnquiryDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='/faqs/details'
          element={
            <SuspensedView>
              <FaqQnaDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/professional-services/detail/'
          element={
            <SuspensedView>
              <ServiceDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/detail'
          element={
            <SuspensedView>
              <InformationDepartmentDetail />
            </SuspensedView>
          }
        />

        <Route
          path='businesses/:id/:businessType'
          element={
            <SuspensedView>
              <BusinessPage />
            </SuspensedView>
          }
        />
        <Route
          path='businesses/list'
          element={
            <SuspensedView>
              <BusinessListPage />
            </SuspensedView>
          }
        />
        <Route
          path='businesses/list/detail'
          element={
            <SuspensedView>
              <BusinessDetail />
            </SuspensedView>
          }
        />
        <Route
          path='businesses/:id/:businessType/detail'
          element={
            <SuspensedView>
              <BusinessDetail />
            </SuspensedView>
          }
        />

        <Route
          path='information/*'
          element={
            <SuspensedView>
              <InfoDepartmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='content-management/*'
          element={
            <SuspensedView>
              <ManagementHeader />
            </SuspensedView>
          }
        />

        <Route
          path='offers/:id/:OfferType'
          element={
            <SuspensedView>
              <OfferPage />
            </SuspensedView>
          }
        />
        <Route
          path='offers/:id/:OfferType/detail'
          element={
            <SuspensedView>
              <OfferDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='offers-types'
          element={
            <SuspensedView>
              <OfferTypesWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='blogs/:id/:blogCategory/*'
          element={
            <SuspensedView>
              <BlogPage />
            </SuspensedView>
          }
        />
        <Route
          path='blog-categories'
          element={
            <SuspensedView>
              <BlogCategoryWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='community-corner'
          element={
            <SuspensedView>
              <PostCategoryWrapper />
            </SuspensedView>
          }
        />
        {/* <Route
          path='community-corner/*'
          element={
            <SuspensedView>
              <PostPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='community-corner/:id/:postCategory/*'
          element={
            <SuspensedView>
              <PostPage />
            </SuspensedView>
          }
        />
        <Route
          path='news/:id/:newsCategory/*'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
        <Route
          path='news-categories'
          element={
            <SuspensedView>
              <NewsCategoryWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='real-estate/*'
          element={
            <SuspensedView>
              <RealEstatePage />
            </SuspensedView>
          }
        />
        <Route
          path='plans/*'
          element={
            <SuspensedView>
              <PlanPage />
            </SuspensedView>
          }
        />
        <Route
          path='payments'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='job-management/*'
          element={
            <SuspensedView>
              <JobManagementPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='product-management/*'
          element={
            <SuspensedView>
              <ProductManagementPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='faqs'
          element={
            <SuspensedView>
              <FaqQnaWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
