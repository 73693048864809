import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  phone?: string
}

const Phone: FC<Props> = ({phone}) => (
  <div className='d-flex flex-column'>
    <p className='mb-1 text-sm'>{phone ? phone : ''}</p>
  </div>
)

export {Phone}
