import { useLocation } from 'react-router-dom'
import _ from 'lodash'

type BusinessPageInfo = {
  businessId: string
  page?: string
  capitalizePage: string
}

const useBusinessPageInfo = (): BusinessPageInfo => {
  const location = useLocation()
  const pageName = location.pathname.split('/').pop()
  const capitalizePage = _.chain(pageName)
    .split('-')
    .map((word) => {
      const capitalizedWord = _.capitalize(word)
      return word.endsWith('s') ? capitalizedWord.slice(0, -1) : capitalizedWord
    })
    .join(' ')
    .value()
  const page = pageName?.replace(/\s+/g, '-').toLowerCase()
  const pagesArr = location.pathname.split('/')
  const businessCategory = pagesArr[pagesArr.length - 2]
  console.log("yehi business id ki baat ho rhi hai ", businessCategory)

  return { businessId: businessCategory, page, capitalizePage }
}

export default useBusinessPageInfo
